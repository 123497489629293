import './style.sass'
import axios from '@/axios'
import { DefaultTable, DefaultTableExposed } from 'apptimizm-ui'
import { defineComponent, ref, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ProductSpecification, ProductSpecificationMeta } from './types'
import { TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'

export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const meta = new ProductSpecificationMeta()

    const headers: TableHeader[] = [
      { name: 'Номер', minWidth: '15px' },
      { name: 'Название товара', minWidth: '180px' },
      { name: 'Код товара', minWidth: '130px' },
      { name: 'Штрихкод', minWidth: '100px' }
    ]

    const line = (item: ProductSpecification) => {
      return (
        <tr>
          <td>{item.number}</td>
          <td>{item.extendedTitle}</td>
          <td>{item.vendorCode}</td>
          <td>{item.barcode}</td>
        </tr>
      )
    }

    return (): JSX.Element => (
      <div class="layout specification-page">
        <div class="close">
          <img class="close-svg" src={require('../../assets/image/close.svg')} alt="close" onClick={() => router.push({ name: 'products' })}/>
        </div>
        <div class='table-wrapper lock-table-overflow'>
          <DefaultTable
            key={meta.endpoint + `?project=${route.params.id}&vendor_code=${route.query.vendorcode || ''}`}
            axios={axios}
            endpoint={meta.endpoint + `?project=${route.params.id}&vendor_code=${route.query.vendorcode || ''}`}
            ref={table}
            line={line}
            headers={headers}
            itemConverter={(v: ProductSpecification) => meta.load(v)}
            responseItemsKey="results"
            responseTotalKey="count"
            paginationType="page"
            perPage={20}
          />
        </div>
      </div>
    )
  }
})